import React from "react"
import { Link } from "gatsby"
import DynamicImage from "../components/dynimage"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomeConsulting from "../sections/home-consulting"
import vepcSvg from "../images/solutions/vepc-figure1.svg"

const VirtualEvolvedPacketCore = () => (
  <Layout>
    <SEO title="Virtual Evolved Packet Core" />
    {/* <!--================Common Banner Area =================--> */}
    <section className="banner_area banner_area_07">
      <div className="banner_inner d-flex align-items-center">
        <div
          className="overlay bg-parallax"
          data-stellar-ratio="0.9"
          data-stellar-vertical-offset="0"
          data-background=""
        ></div>
        <div className="container">
          <div className="banner_content text-center">
            <h2>Virtual Evolved Packet Core</h2>
            <div className="page_link">
              <Link to="/">Home</Link>
              <Link to="#">Virtual Evolved Packet Core</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================End Common Banner Area =================--> */}
    <section className="feature_area p_120">
      <div className="container">
        <div className="main_title">
          <h2>Virtual Evolved Packet Core (vEPC)</h2>
          <p>
            NgNapps virtual evolved packet core (vEPC) solution offers mobile
            network operators the agility and flexibility necessary to keep up
            with traffic demand, to scale their networks and deliver
            differentiated services tailored to specific use cases such as:
            Consumer, IoT, private LTE, Wi-Fi, VoLTEand 5G services.
          </p>
          <br />
          <p>
            Our full solution of vEPC includes a Home Subscriber Server (vHSS),
            Mobility Management Entity (vMME), Serving Gateway (vSGW), Packet
            Data Network Gateway(vPGW) and Voice-over-LTE (vIMS for VoLTE). It
            is one of the industry’s most efficient mobile core solutions that
            can be deployed in fully virtualised environments or enterprise data
            centres.
          </p>
        </div>
      </div>
    </section>
    {/* <!--================Feature Area =================--> */}
    <section class="feature_area p_120">
      <div class="container">
        <div class="main_title">
          <h2>Hosted vEPC in Public Cloud</h2>
          <div className="mb-4 d-flex justify-content-center">
            <div className="col-md-6 feat_image">
              <DynamicImage src="solutions/vepc_01.jpg" />
            </div>
          </div>
          <p>
            Deliver innovative 4G and 5G services over a scalable cloud
            infrastructure, test new markets cost-effectively without the
            excessive capital investment with NgNapps vEPC solution, a
            cloud-based offering providing a scalable, flexible mobile network
            in Public cloud.
          </p>
        </div>
      </div>
    </section>
    {/* <!--================End Feature Area =================--> */}

    {/* <!--================Feature Area =================--> */}
    <section class="feature_area p_120">
      <div class="container">
        <div class="main_title">
          <h2>
            Single Anchor Point for all RAT types - GPRS,UMTS,LTE,and 5G-NSA.
          </h2>
          <div className="mb-4 d-flex justify-content-center">
            <div className="col-md-6 feat_image">
              <DynamicImage src="solutions/vepc_02.jpg" />
            </div>
          </div>
          <p>
            Decomposed architecture allows operator deployment as a standalone
            function, or combined operation, scaled to the specific use case of
            the mobile operator.
          </p>
        </div>
      </div>
    </section>
    {/* <!--================End Feature Area =================--> */}

    {/* <!--================Feature Area =================--> */}
    <section class="feature_area p_120">
      <div class="container">
        <div class="main_title">
          <h2>Reduce Service Creation Time & Costs</h2>
          <div className="mb-4 d-flex justify-content-center">
            <div className="col-md-6 feat_image">
              <DynamicImage src="solutions/vepc_03.jpg" />
            </div>
          </div>
          <p>
            NgNapps vEPCreduces service creation time/costs through automated
            service provisioning and configuration.vEPC can also lower CAPEX and
            OPEX by reducing reliance on specialized hardware, while speeding up
            service delivery.
          </p>
          <br />
          <p>
            <figure>
              <img src={vepcSvg} alt="virtual Evolved Packet Core Network" />
              <figcaption className="mt-4">
                Fig:virtual Evolved Packet Core Network
              </figcaption>
            </figure>
          </p>
        </div>
      </div>
    </section>
    {/* <!--================End Feature Area =================--> */}

    <HomeConsulting />
  </Layout>
)

export default VirtualEvolvedPacketCore
